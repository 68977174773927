.admin-form-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.admin-form-container h1 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 20px;
}

.admin-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input[type="text"],
.form-group input[type="url"],
.form-group input[type="file"] {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.form-group input[type="file"] {
  padding: 5px;
}

.ReactQuill {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.submit-button {
  background-color: #299b8a;
  color: #fff;
  font-size: 1rem;
  padding: 10px 15px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

/* Styling for image preview section */
.image-preview {
  margin-top: 10px;
}

.image-preview p {
  font-weight: bold;
  margin-bottom: 10px;
}

.image-preview div {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.image-preview img {
  max-width: 400px;
  max-height: 400px;
  border-radius: 6px;
  object-fit: contain;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.7);
  transition: transform 0.3s ease;
}

.image-preview img:hover {
  transform: scale(1.05);
}
/* Styling for multiple images preview section */

.images-preview {
  margin-top: 10px;
}

.images-preview p {
  font-weight: bold;
  margin-bottom: 10px;
}

.images-preview div {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  justify-content: space-around;
}

.images-preview img {
  max-width: 600px;
  max-height: 200px;
  object-fit: contain;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.7);
  transition: transform 0.3s ease;
}

.images-preview img:hover {
  transform: scale(1.05);
}
.projects-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;
  justify-content: space-around;
}
.projects-list .image-preview img {
  max-width: 300px;
  max-height: 300px;
}
