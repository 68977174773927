.About_container {
  position: relative;
  display: flex;
  justify-content: space-around;
  gap: 20px;
  align-items: center;
}
.About {
  position: relative;
  min-height: 50vh;

  background-color: #efefef;
  padding: 50px;
  padding-bottom: 10px;
  color: #7f5f97;
  font-size: 19px;
}
.About h1,
.About h2,
.About h3 {
  color: #503e99;
  font-size: 25px;
  margin: 0;
  font-style: italic;
}
.About h1 {
  margin: 0;
  font-size: 35px;
  font-style: normal;
}

.grain {
  z-index: 0;
  opacity: 0.05;
  background-image: url(../assets/Glitch.gif);
  background-position: 0 0;
  background-size: auto;
  display: block;
  position: absolute;
  top: -0%;
  bottom: -0%;
  left: 0%;
  right: 0%;
}
.About hr {
  width: 50%;
}
.About_main {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 20px;
}
.About_main div {
  text-align: justify;
}
.About_projects img {
  width: 100%;
  border-radius: 200px 200px 0px 0px;
}
.cvox {
  position: relative;
  background-color: rgb(12, 12, 12);
  padding: 20px;
  width: auto;
  text-transform: capitalize;
  border-radius: 0px 0px 20px 20px;
  font-size: 20px;
  transition: 0.5s;
  font-weight: 600;
}
.About_projects {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1500px;
  transition: 0.5s;
  gap: 0;
}
.About_projects:hover {
  gap: 20px;
}
.About_projects:hover .cvox {
  gap: 20px;
}
.wave_hand {
  animation-name: wave_hand-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}
.Projects h3 {
  white-space: nowrap; /* Prevent text from wrapping to a new line */
  overflow: hidden; /* Hide any overflowed content */
  text-overflow: ellipsis; /* Show ellipsis when text is too long */
  max-width: 200px; /* Adjust the max-width to the desired length */
}
@keyframes wave_hand-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
.cvox:hover {
  background-color: #efefef;
  color: #299b8a;
  cursor: pointer;
}
.About a {
  all: unset;
  width: 100%;
}
@media only screen and (max-width: 1300px) {
  .About_container {
    flex-direction: column;
  }
  .About_projects {
    width: 60vw;
    padding: 0px;
  }
  .cvox {
    font-size: 18px;
  }
  .About_main {
    font-size: 14px;
    padding-inline: 10px;
  }
  .About h1,
  .About h2,
  .About h3 {
    font-size: 20px;
  }
  .About h1 {
    font-size: 27px;
  }
}
