.footer {
  background-color: #1d1f1d;
  display: flex;
  height: 60vh;
  justify-content: space-around;
  align-items: flex-start;
  padding-block: 100px;
  padding-inline: 50px;
  color: white;
  font-family: "Sarabun", sans-serif;
  position: relative;
  overflow: hidden;
  border-top: solid #299b8a;
}
.footer > div {
  width: 20%;
}
.footer h1 {
  font-family: "Poppins", sans-serif;
}
.footer .intro {
  text-align: justify;

  width: 40%;
}
.footer .intro h3 {
  font-weight: 400;
  font-style: italic;
  font-family: "Sarabun", sans-serif;
}
.footer .ocean2 {
  height: 5%;
  width: 100%;
  position: absolute;

  left: 0;
  bottom: 0;
  background: #299b8a;
}
.footer .wave3 {
  background: url(../assets/wave.svg) repeat-x;
  position: absolute;
  width: 6400px;
  top: -198px;
  left: 0;
  height: 198px;
  transform: translate(0, 0, 0);
  animation: wave 7s ease infinite;
}

.footer .wave3:nth-of-type(2) {
  top: -168px;
  animation: swell 4s ease infinite;
  opacity: 1;
}
.footer a img {
  width: 40px;
  border: solid white;
  border-radius: 10px 10px 10px 10px;
  padding: 4px;
  transition: 0.2s;
}
.footer a img:hover {
  border: solid #299b8a;
}
.footer .contact_info {
  display: flex;
  flex-direction: column;
}
.footer .contact_info div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}
.footer a:last-of-type {
  border-right: none;
}
.footer a {
  margin: 0;
  text-decoration: none;
  color: inherit;
}
.footer a:hover h3 {
  color: #299b8a;
}
@media only screen and (max-width: 1100px) {
  .footer {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 400px;
  }
  .footer > div {
    width: 100%;
  }
  .footer .intro {
    width: 70%;
  }
}
