.Home_main {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-inline: 20%;
  color: #299b8a;
  font-family: "Pacifico", cursive;
  height: 70vh;
}
.Home_main_container {
  height: 91vh;
  position: relative;
  overflow: hidden;
}
.Home_main h1 {
  text-transform: capitalize;
  font-size: 2.6vw;
}
.Home_main img {
  width: 30%;
  rotate: -5deg;
  border-radius: 200px 10px 200px 200px;
}
@keyframes cube {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    transform: scale(20) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}
@keyframes cube2 {
  from {
    transform: scale(0) rotate(0deg) translate(-50%, -50%);
    opacity: 1;
  }
  to {
    transform: scale(10) rotate(960deg) translate(-50%, -50%);
    opacity: 0;
  }
}
.background {
  overflow: hidden;
  width: 97%;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  position: absolute;
  overflow: hidden;
}
.background li {
  border-radius: 2px 2px 2px 2px;
  position: absolute;
  top: 80vh;
  left: 45vw;
  width: 10px;
  height: 10px;
  border: solid 1px #e5e5e5;
  color: transparent;
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  animation: cube 10s ease-in forwards infinite;
  border-color: #59bca3;
}
undefined .background li:nth-child(0) {
  animation-delay: 0s;
  left: 94vw;
  top: 54vh;
}

.background li:nth-child(1) {
  animation-delay: 2s;
  left: 0vw;
  top: 29%;
}

.background li:nth-child(2) {
  animation-delay: 4s;
  left: 63vw;
  top: 75%;
}

.background li:nth-child(3) {
  animation-delay: 6s;
  left: 19vw;
  top: 73%;
}

.background li:nth-child(4) {
  animation-delay: 8s;
  left: 89vw;
  top: 49%;
}

.background li:nth-child(5) {
  animation-delay: 10s;
  left: 73vw;
  top: 12%;
}
.background li:nth-child(6) {
  top: 85%;

  animation: cube2 10s ease-in forwards infinite;
}

.ocean {
  height: 5%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #299b8a;
}

.wave {
  background: url(../assets/wave.svg) repeat-x;
  position: absolute;
  width: 6400px;
  top: -198px;
  left: 0;
  height: 198px;
  transform: translate(0, 0, 0);
  animation: wave 7s ease infinite;
}

.wave:nth-of-type(2) {
  top: -168px;
  animation: swell 4s ease infinite;
  opacity: 1;
}

@keyframes wave {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -1600px;
  }
}
@keyframes swell {
  0%,
  100% {
    transform: translate(0, -30px);
  }
  50% {
    transform: translate(0, 5px);
  }
}
@media only screen and (max-width: 1200px) {
  .Home_main {
    flex-direction: column;

    justify-content: center;
  }
  .Home_main h1 {
    font-size: 5vw;
  }
  .Home_main img {
    z-index: 12;
    width: 50%;
    rotate: -5deg;
    border-radius: 200px 10px 200px 200px;
  }
}
@media only screen and (max-width: 600px) {
  .Home_main h1 {
    font-size: 7vw;
  }
  .Home_main img {
    width: 70%;
    rotate: -5deg;
    border-radius: 200px 10px 200px 200px;
  }
}
