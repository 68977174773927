.Skills h1,
.Skills h2,
.Skills h3 {
  color: #503e99;
  font-size: 25px;
  margin: 0;
}
.Skills h1 {
  margin: 0;
  font-size: 35px;
}
.Skills hr {
  width: 50%;
}

.Skills {
  padding: 1em;
  position: relative;
  background-color: #efefef;
}

.Icon img {
  float: left;
  height: 80px;
  object-fit: cover;
  filter: drop-shadow(rgba(0, 0, 0, 0.35) 0px 5px 15px);
}
.Text {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: bold;
  transition: ease 0.5s;
}

.SkillContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.3em 2em 0.3em 2em;
  border: 2px solid #64d4c4;

  border-radius: 10px 10px 10px 10px;
  position: relative;
  height: 6em;
}

.Container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1em; /* Optional: Add some gap between the grid items */
  padding: 1em;
}

.overlay {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  opacity: 0;
  transition: ease 0.35s;
}

/* .overlay--blur {
	backdrop-filter: blur(5px);
}

.overlay--primary {
	background: #009578;
} */

.overlay > * {
  transform: translateY(20px);
  transition: transform 0.25s;
}

.SkillContainer:hover .overlay {
  opacity: 1;
  width: 100%;
}

.SkillContainer:hover .Text {
  color: #299b8a;
}

.overlay:hover > * {
  transform: translateY(0);
}

@media only screen and (max-width: 700px) {
  .Container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media only screen and (min-width: 1500px) {
  .Container {
    grid-template-columns: repeat(3, 1fr);
  }
}
