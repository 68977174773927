.Projects_nav {
  display: flex;
  justify-content: space-around;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 20px;
  border-radius: 10px 10px 10px 10px;

  z-index: 1;
}
.Projects_nav h2 {
  transition: 0.2s;
  cursor: pointer;
  z-index: 1;
}
.Projects_nav h2:hover {
  color: #299b8a;
}
.Projects_main {
  border-top: solid #299b8a;
  background-color: #222831;
  position: relative;
  min-height: 59vh;
  padding: 50px;
  font-size: 17px;
  color: white;
  z-index: 1;

  background-attachment: fixed;
}
.Projects {
  z-index: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  font-family: "Sarabun", sans-serif;
  overflow-x: hidden;
}
.Project {
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px 10px 10px 10px;
  margin-block: 10px;
  text-transform: capitalize;
  transition: 0.3s;
  font-weight: 600;
  box-shadow: 0px 0px 0px 2px #2ca290;
}
.Project h2 {
  color: white;
}
.Project:hover img {
  width: 430px;
  height: 230px;
}
.Project:hover {
  color: #299b8a;
  cursor: pointer;
  box-shadow: 10px 10px 1px #2ca290;
}

.Project img {
  object-fit: cover;
  width: 400px;
  height: 200px;
  border-radius: 10px 10px 10px 10px;
  transition: 0.3s;
}

.Overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.427);
  backdrop-filter: blur(10px);
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  visibility: hidden;
  border: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Sarabun", sans-serif;
  transition: 0.6s;
  opacity: 0;
}
.Overlay .atropos {
  width: 100%;
  display: flex;
  height: 70%;
  color: white;
  justify-content: center;
  align-items: center;
  margin-inline-end: 9%;
}
.Overlay .atropos-inner,
.Overlay .atropos-rotate,
.Overlay .atropos-scale {
  width: 100%;
  height: 100%;
}
.Overlay #data_overlay {
  width: 100%;
  display: flex;
  height: 100%;
  color: white;
  justify-content: center;
  align-items: center;
  margin-inline: 5%;
}
.Overlay iframe {
  height: 100%;
  object-fit: contain;
  border-radius: 10px 10px 10px 10px;
}
.Overlay .Overlay_img {
  object-fit: cover;

  border-radius: 10px 10px 10px 10px;
}
.Overlay #data_overlay > #data_text {
  text-align: justify;
  background-color: #222831;
  padding-block: 40px;
  border-radius: 10px 10px 10px 10px;
  padding-inline: 20px;
  box-shadow: 10px 10px 1px #2fb29e;
  display: flex;
  flex-direction: column;
  gap: 20px;
  letter-spacing: 0.7px;
  line-height: 23px;
  min-width: 15%;
  max-width: 35%;
  min-height: 40%;
  max-height: 70%;
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
}
.Overlay #data_overlay > #data_text h2 {
  margin: 0;

  text-align: center;
  text-transform: capitalize;
  color: #2fb29e;
  letter-spacing: 5px;
}
#data_text a {
  text-decoration: none;
  font-weight: bold;
  color: #2fb29e;
  transition: 0.1s;
}
#data_text a:hover {
  color: #19e2c4;
}
#data_carousel {
  position: relative;
  width: 54.5%;
  height: 75%;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  justify-content: center;
}
.carousel-root {
  width: 91%;
}
.control-dots {
  top: 100%;
}
.carousel.carousel-slider {
  overflow: visible !important;
}
.carousel-root,
.carousel-slider,
.slider-wrapper,
.slider,
.slide,
.slide div {
  height: 100%;
}
.slide {
  width: 100%;
}
#data_carousel img,
#data_carousel video {
  transform-origin: center center;
  transition: transform 0.5s;
  max-height: 100%;
  border-radius: 10px 10px 10px 10px;
  max-width: 100%;
  object-fit: contain;
}

#cancel_svg {
  position: absolute;
  right: 0%;
  top: 3%;
  cursor: pointer;
  z-index: 50;
}
#cancel_svg:hover {
  filter: sepia(100) saturate(100) hue-rotate(90deg);
}
.star {
  z-index: 0;
  opacity: 0.1;
  background-image: url(../assets/star.gif);
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  display: block;
  position: absolute;
  top: -0%;
  bottom: -0%;
  left: 0%;
  right: 0%;
}

.slide-in-fwd-right {
  -webkit-animation: slide-in-fwd-right 0.4s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-fwd-right 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.orbitProgress {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -12%);
}

/* ----------------------------------------------
 * Generated by Animista on 2024-6-21 8:27:27
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-fwd-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-fwd-right {
  0% {
    -webkit-transform: translateZ(-1400px) translateX(1000px);
    transform: translateZ(-1400px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
    transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-fwd-right {
  0% {
    -webkit-transform: translateZ(-1400px) translateX(1000px);
    transform: translateZ(-1400px) translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0) translateX(0);
    transform: translateZ(0) translateX(0);
    opacity: 1;
  }
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
/* ----------------------------------------------
 * Generated by Animista on 2024-7-9 0:37:8
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 1300px) {
  .Projects_nav h2 {
    font-size: 20px;
  }

  .Projects_main {
    padding: 20px;
  }
  .Project img {
    object-fit: cover;
    width: 250px;
    height: 150px;
    border-radius: 10px 10px 10px 10px;
    transition: 0.3s;
  }
  .Overlay #data_overlay {
    flex-direction: column;

    margin-inline: 0%;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 40px;
  }

  .Overlay .Overlay_img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 10px 10px 10px 10px;
  }
  #data_carousel {
    width: 100%;
  }
  .Overlay .atropos {
    margin-inline-end: 0%;
  }

  .Overlay #data_overlay > #data_text {
    box-shadow: 0px 5px 1px #2fb29e;
    min-height: 30%;
    max-width: 50%;
    font-size: 1rem;
    letter-spacing: initial;
    padding-bottom: 60px;
    scrollbar-width: initial;
  }
  .Overlay #data_overlay > #data_text::-webkit-scrollbar-thumb {
    border-radius: 5px 5px 5px 0px;
    background: #299b8a;
  }
  .Overlay #data_overlay > #data_text::-webkit-scrollbar-track {
    border-radius: 5px 5px 5px 0px;
  }
  #data_carousel img,
  #data_carousel video {
  }
}
