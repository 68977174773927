.Header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  top: 2px;
  text-transform: uppercase;
}
.Header h3 {
  padding-right: 100px;
  border-right: 1px solid #000;
  padding-left: 100px;
  transition: 0.2s;
}
.Header img {
  display: none;
}
.Header a:last-of-type {
  border-right: none;
}
.Header a {
  margin: 0;
  text-decoration: none;
  color: inherit;
}
.Header a:hover h3 {
  color: #299b8a;
}
.Header .menu_collapse {
  flex-direction: column;
  width: 0;
  height: 0;
  opacity: 0;
  color: white;
  display: none;
  align-items: center;
  margin-top: 50%;
  gap: 40px;
  transition: 0.3s;
  font-size: 1rem;
  background-color: white;
}
#contact_nav h3 {
  border: none;
}
@media only screen and (max-width: 1200px) {
  .Header img {
    display: block;
    width: auto;
    height: 100px;
    position: absolute;
    top: 0;
    right: 0;
  }
  .Header h3 {
    padding-right: 100px;
    border-right: none;
  }
  .Header {
    position: fixed;
    transition: 0.3s;

    height: 100px;

    right: 0px;
    background-color: rgba(0, 0, 0, 0);
    z-index: 11;
    top: 0;
  }
  .Header > a {
    display: none;
  }
}
@media only screen and (max-width: 600px) {
  .Header img {
    display: block;
    width: auto;
    height: 50px;
    position: absolute;
    top: 0;
    right: 0;
  }
  .Header {
    transition: 0.3s;

    height: 100px;

    right: 0px;
    background-color: rgba(0, 0, 0, 0);
    z-index: 11;
    top: 0;
  }
  .Header > a {
    display: none;
  }
}
